
































































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import AgencyInfos from "@/components/agencies/AgencyInfos.vue"

@Component({
  components: {
    AssociationModal: () => import("@/components/modals/AssociationModal.vue"),
    AgencyInfos,
  },
})
export default class AssociatedAgencies extends Vue {
  @Prop({ required: true }) house!: House
  randomAssociateKey = Math.random()

  get proAgencies() {
    return this.house.agencies?.filter((agency) => !agency.individual)
  }
  get isProAgencies() {
    return this.proAgencies && this.proAgencies.length > 0
  }
  get individualAgencies() {
    return this.house.agencies?.filter((agency) => agency.individual)
  }
  get isIndividualAgencies() {
    return this.individualAgencies && this.individualAgencies.length > 0
  }

  async updateIndividualComment() {
    await this.house.save({
      method: "PUT",
      url: `houses/${this.house.id}`,
      data: {
        house: { individual_comment: this.house.individual_comment },
      },
    })
  }
  openAssociateModal() {
    this.randomAssociateKey = Math.random()
    // @ts-ignore
    setTimeout(() => this.$refs.associateModal.open(), 0)
  }
}
