








































import { Component, Vue, Prop } from "vue-property-decorator"
import Agency from "~/models/Agency"
import ActionTeam from "~/models/ActionTeam"
import OcularIcon from "~/components/OcularIcon.vue"
import OcularPhone from "~/components/OcularPhone.vue"
import router from "@/router"
import AgentInfos from "@/components/agents/AgentInfos.vue"

@Component({
  components: {
    AgentInfos,
    OcularIcon,
    OcularPhone,
  },
})
export default class AgencyInfos extends Vue {
  @Prop({ required: true }) agency!: Agency
  @Prop({ default: [] }) actionTeams!: Array<ActionTeam>

  verified(agent_id: number) {
    return (
      this.actionTeams.filter(
        (actionTeam) =>
          actionTeam.status == "Verification" && actionTeam.agent_id == agent_id
      ).length > 0
    )
  }

  gotToAgency() {
    const newRoute = `${this.$route.fullPath.replace(
      "description",
      `agencies/${this.agency.id}`
    )}`
    if (newRoute != this.$route.fullPath) router.push(newRoute)
  }
}
